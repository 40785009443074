import React, { useState } from 'react';
import './App.css';
import SignIn from './components/SignIn';
import Welcome from './components/Welcome';
import Confirmation from './components/Confirmation';
import logo from './logo.png';

function App() {
  const [page, setPage] = useState('signin');
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNextClick = () => {
    if (!inputValue) {
      setErrorMessage('Enter an email or phone number.');
    } else if (!validateEmailOrPhone(inputValue)) {
      setErrorMessage('Enter a valid email or phone number.');
    } else if (isBogusEmail(inputValue)) {
      setErrorMessage('Couldn\'t find your Jamie Account');
    } else {
      setErrorMessage('');
      setPage('welcome');
    }
  };

  const validateEmailOrPhone = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{7,}$/;
    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const isBogusEmail = (value) => {
    const bogusEmails = ['fuckyou@fuckyou.com'];
    const bogusPhones = ['01234567'];
    return bogusEmails.includes(value.toLowerCase()) || bogusPhones.includes(value);
  };

  const handleSend = () => {
    setPage('confirmation');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNextClick();
    }
  };

  return (
    <div className="App">
      {page === 'signin' && (
        <div className="container">
          <div className="left-section">
            <div className="logo-container">
              <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="text-container">
              <div className="sign-in-text">Contact me</div>
              <div className="account-text">Use your Jamie Account</div>
            </div>
          </div>
          <div className="right-section">
            <div className="input-group">
              <div className={`input-container ${errorMessage ? 'error' : ''}`}>
                <input
                  type="text"
                  id="email"
                  className="input"
                  placeholder=" "
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown} // Ensure this is attached to the input
                  required
                />
                <label htmlFor="email" className="input-label">Email or phone</label>
              </div>
              {errorMessage && (
                <div className="error-message">
                  <svg aria-hidden="true" className="error-icon" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                  {errorMessage}
                </div>
              )}
              <a href="#" className="forgot-email">Forgot email?</a>
            </div>
            <div className="info-text">
              <p>Not your computer? Use Guest mode to sign in privately.</p>
              <a href="#" className="learn-more">Learn more about using Guest mode</a>
            </div>
            <div className="actions">
              <button className="create-account">Create account</button>
              <button className="next-button" onClick={handleNextClick}>Next</button>
            </div>
          </div>
        </div>
      )}
      {page === 'welcome' && <Welcome email={inputValue} onSend={handleSend} />}
      {page === 'confirmation' && <Confirmation />}
    </div>
  );
}

export default App;
